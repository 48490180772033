import Vue from 'vue'
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { Loading } from 'element-ui'
import router from '../router/index'
const host = location.origin.indexOf('192.168') > 0 ? '/api' : location.origin

const config = {
  // 基础url前缀
  baseURL: `${host}${catalog_api_url}`,
  // 请求头信息
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  // 参数
  data: {},
  // 设置超时时间
  timeout: 10000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: 'json',
}
// var proxy_tdd = ''
// console.log(process.env.NODE_ENV, '99999')

// if (process.env.NODE_ENV == 'production') {
//   proxy_tdd = '/chain/tddchain/'
// } else {
//   proxy_tdd = '/stores/tddchain/'
// }
// 添加请求拦截器

// const loading = this.$loading({
//   lock: true,
//   text: 'Loading',
//   spinner: 'el-icon-loading',
//   background: 'rgba(0, 0, 0, 0.7)',
// })
// setTimeout(() => {
//   loading.close()
// }, 2000)
console.log('111', host)
console.log('222', catalog_api_url)
const request = (options) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: config.headers,
      timeout: config.timeout,
    })
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        if (
          config.method.toLocaleLowerCase() === 'post' ||
          config.method.toLocaleLowerCase() === 'put'
        ) {
          // config.data = JSON.stringify(config.data)
        }
        console.log('apiConfgi', config.url)
        // const loading = this.$loading({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        // setTimeout(() => {
        //   loading.close()
        // }, 2000)
        return config
      },
      (error) => {
        return reject(error)
      }
    )
    // 添加响应拦截器

    instance.interceptors.response.use(
      (response) => {
        console.log(response, 'response')
        if (response.status === 200) {
          // 请求成功
          if (response.data.status === 5) {
            let url = window.location.origin + catalog_url + 'index.html'
            window.location.replace(url)
          }
        }
        if (response.data.code == -401) {
          router.push('login')
        }
        return response
      },
      (err) => {
        console.log(err, 'err')
        if (err.response.status === 504 || err.response.status === 404) {
          console.log('服务器被吃了⊙﹏⊙∥')
        } else if (err.response.status === 401) {
          console.log('登录信息失效⊙﹏⊙∥')
          //wx.miniProgram.switchTab({url: '/pages/shouye/shouye'})
        } else if (err.response.status === 500) {
          console.log('服务器开小差了⊙﹏⊙∥')
        }
        return reject(err)
      }
    )

    instance(options)
      .then((res) => {
        console.log('instanceRes', res.data.code)

        // if (res.data.code == -401) {
        //   this.$router.push('login')
        // }

        resolve(res.data)

        return false
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const requestPost = (url, data) => {
  return request({
    url: `${url}`,
    method: 'post',
    data,
  })
}
const requestGet = (url, params) => {
  return request({
    url: `${url}`,
    method: 'get',
    params,
  })
}

export default {
  requestPost,
  requestGet,
}
// Vue.prototype.requestPost = requestPost;
// Vue.prototype.requestGet = requestGet;
