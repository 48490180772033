import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/qukuaigaodu',
    name: 'qukuaigaodu',
    component: () => import('@/views/qukuaigaodu.vue'),
  },
  {
    path: '/shanglianjilu',
    name: 'shanglianjilu',
    component: () => import('@/views/shanglianjilu.vue'),
  },
  {
    path: '/shouzhiDetails',
    name: 'shouzhiDetails',
    component: () => import('@/views/shouzhiDetails.vue'),
  },

  {
    path: '/zhanghaoxinxi',
    name: 'zhanghaoxinxi',
    component: () => import('@/views/zhanghaoxinxi.vue'),
  },
  {
    path: '/wodelianzhanghu',
    name: 'wodelianzhanghu',
    component: () => import('@/views/wodelianzhanghu.vue'),
  },
  {
    path: '/payFuel',
    name: 'payFuel',
    component: () => import('@/views/payFuel.vue'),
  },
  {
    path: '/allChain',
    name: 'allChain',
    component: () => import('@/views/allChain.vue'),
  },
  {
    path: '/meetingChain',
    name: 'meetingChain',
    component: () => import('@/views/meetingChain.vue'),
  },
  {
    path: '/qiyeChain',
    name: 'qiyeChain',
    component: () => import('@/views/qiyeChain.vue'),
  },
  {
    path: '/nftChain',
    name: 'nftChain',
    component: () => import('@/views/nftChain.vue'),
  },

  {
    path: '/payRecord',
    name: 'payRecord',
    component: () => import('@/views/payRecord.vue'),
  },
  {
    path: '/gerenrenzhen',
    name: 'gerenrenzhen',
    component: () => import('@/views/gerenrenzhen.vue'),
  },
  {
    path: '/qiyerenzhen',
    name: 'qiyerenzhen',
    component: () => import('@/views/qiyerenzhen.vue'),
  },

  {
    path: '/renzhenchengong',
    name: 'renzhenchengong',
    component: () => import('@/views/renzhenchengong.vue'),
  },
  {
    path: '/renzhenchengong_qiye',
    name: 'renzhenchengong_qiye',
    component: () => import('@/views/renzhenchengong_qiye.vue'),
  },
  {
    path: '/wanchengshanglian',
    name: 'wanchengshanglian',
    component: () => import('@/views/wanchengshanglian.vue'),
  },
  {
    path: '/lianDetail',
    name: 'lianDetail',
    component: () => import('@/views/lianDetail.vue'),
  },
  {
    path: '/lianDetail_jg',
    name: 'lianDetail_jg',
    component: () => import('@/views/lianDetail_jg.vue'),
  },
  {
    path: '/lianDetailmm',
    name: 'lianDetailmm',
    component: () => import('@/views/lianDetailmm.vue'),
  },
  {
    path: '/lianRecord',
    name: 'lianRecord',
    component: () => import('@/views/lianRecord.vue'),
  },
  {
    path: '/lianRecord_jg',
    name: 'lianRecord_jg',
    component: () => import('@/views/lianRecord_jg.vue'),
  },

  {
    path: '/commonProblem',
    name: 'commonProblem',
    component: () => import('@/views/commonProblem.vue'),
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import('@/views/aboutMe.vue'),
  },
  {
    path: '/jiedian',
    name: 'jiedian',
    component: () => import('@/views/jiedian.vue'),
  },
  {
    path: '/addzi',
    name: 'addzi',
    component: () => import('@/views/addzi.vue'),
  },
  {
    path: '/zilist',
    name: 'zilist',
    component: () => import('@/views/zilist.vue'),
  },
  {
    path: '/ziDetail',
    name: 'ziDetail',
    component: () => import('@/views/ziDetail.vue'),
  },
  {
    path: '/editzi',
    name: 'editzi',
    component: () => import('@/views/editzi.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
})

export default router
