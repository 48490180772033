<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  // created() {
  //   let userInfo = this.$store.state.userInfo
  //   console.log(this.$store.state.userInfo)
  //   if (userInfo === '' || userInfo === null || userInfo === undefined) {
  //     this.$router.push('login')
  //   }
  // },
}
</script>

<style lang="less" scoped>
#app {
  font-family: 'Noto Sans SC', Arial, Helvetica, Arial, sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
