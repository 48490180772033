<template>
  <div>
    <div class="mask" @click="tanshow=false" v-if="tanshow"></div>
    <div class="tan1" v-if="tanshow">
      <img src="@/assets/images/yk/tan1.png" alt=""/>
    </div>

    <div class="header">
      <div class="uiop">
        <span
          ><img
            src="@/assets/images/yk/logo.png"
            alt=""
            style="width: 120px; margin-left: 10px"
            @click="routerClick({ name: 'home' })"
        /></span>
        <span class="logolink"
          ><img
            src="@/assets/images/yk/logolink.png"
            alt=""
            style="width: 200px"
        /></span>
      </div>

      <div class="bbbgc"><span @click="jiaru" style="margin-right:20px;">加入节点</span><span @click="tuichu">登出</span></div>
    </div>

    
  </div>
</template>

<script>
export default {
  name: 'NavButton',

  data() {
    return {
      tanshow:false
    }
  },
  methods: {
    routerClick(name) {
      this.$router.push(name)
    },
    tuichu() {
      this.$store.commit('SET_TOKEN', '')
      this.$store.commit('SET_USERINFO', {})
      this.$router.push('login')
    },
    jiaru(){
      this.tanshow = true;
    }
  },
}
</script>

<style lang="less" scoped>
.bbbgc {
  color: #03ddb8;
  margin-right: 30px;
 
  cursor: pointer;
  display:inline-block;
  >span:hover{
    text-decoration: underline;
  }
}


.uiop {
  display: flex;
  align-items: center;
}
.header {
  padding: 20px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}

.logolink {
  margin-left: 50px;
}

.mask{
  position: fixed;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.5);
  z-index: 1000;
}

.tan1{
  position: fixed;
  z-index: 1001;
  left:50%;
  top: 50%;
  margin-left: -407px;
  margin-top:-256px;  
  >img{
    border-radius: 10px;
  }
}
</style>
