<template>
  <div class="container" style="min-width: 1340px">
    <YkHeader></YkHeader>
    <div class="body">
      <!-- <img src="../assets/images/vvv/shouye_bg.png" alt="" width="100%" /> -->
      <div class="body_psa">
        <div class="body_left">
          <img
            :src="userInfo.wx_avatarUrl"
            alt=""
            class="touxiang"
            v-if="userInfo.wx_avatarUrl"
          />
          <img
            src="../assets/images/vvv/morentoux.jpeg"
            alt=""
            class="touxiang"
            v-else
          />
          <span class="left_nicheng" v-if="userInfo.nickName !== '' && userInfo.nickName !==null">{{
            userInfo.nickName
          }}</span>
          <span class="left_nicheng" v-else>{{
            userInfo.mobile
          }}</span>
          <span
            class="left_nicheng"
            v-if="userInfo.nickName === '' && userInfo.mobile === ''"
          >
            低碳卫士</span
          >

          <div class="czbtn" @click="routerClick({ name: 'payFuel' })">去充值</div>

          <div class="left_record">
            <div class="record_box br">
              <div style="cursor:pointer;" class="box_h" @click="routerClick({ name: 'lianRecord' })">
                <img
                  src="../assets/images/vvv/shouye_icon1.png"
                  alt=""
                  class="left_icon"
                />
                <span>上链数量</span>
              </div>
              <div class="box_b">{{ blockchainCount }}</div>
            </div>
            <div class="record_box">
              <div class="box_h">
                <img
                  src="../assets/images/vvv/shouye_icon2.png"
                  alt=""
                  class="left_icon"
                />
                <span>燃料余额</span>
                <!-- <div class="chongzhi" @click="routerClick({ name: 'payFuel' })">
                  充值
                </div> -->
              </div>
              <div class="box_b">{{ coin }}</div>
            </div>
          </div>
          <div
            class="route_item"
            @click="routerClick({ name: 'lianRecord' })"
          >
            <div class="item_l">
              <img
                src="../assets/images/yk/af.png"
                alt=""
                style="width: 28px;"
              />
              <span>上链记录</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div
            class="route_item"
            @click="routerClick({ name: 'wodelianzhanghu' })"
          >
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon3.png"
                alt=""
                style="width: 28px"
              />
              <span>我的链账户</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div class="route_item" @click="routerClick({ name: 'payRecord' })">
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon4.png"
                alt=""
                style="width: 28px"
              />
              <span>充值记录</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div
            class="route_item"
            @click="routerClick({ name: 'shouzhiDetails' })"
          >
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon5.png"
                alt=""
                style="width: 28px"
              />
              <span>收支明细</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div
            class="route_item"
            @click="routerClick({ name: 'zhanghaoxinxi' })"
          >
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon6.png"
                alt=""
                style="width: 28px"
              />
              <span>账号信息</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div
            class="route_item"
            @click="routerClick({ name: 'commonProblem' })"
          >
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon7.png"
                alt=""
                style="width: 28px"
              />
              <span>常见问题</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
          <div class="route_item" @click="routerClick({ name: 'aboutMe' })">
            <div class="item_l">
              <img
                src="../assets/images/vvv/shouye_icon8.png"
                alt=""
                style="width: 28px"
              />
              <span>关于我们</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
           <div class="route_item" v-if="userLevel==1" @click="routerClick({ name: 'jiedian' })">
            <div class="item_l">
              <img
                src="../assets/images/yk/al.png"
                alt=""
                style="width: 28px"
              />
              <span>节点展示</span>
            </div>

            <img
              src="../assets/images/vvv/route-right.png"
              alt=""
              style="width: 6px; margin-right: 13px"
            />
          </div>
        </div>
        <div class="body_right">
          <!---节点航-->
             <div class="line1">
        <div class="line1a">
          <div class="smallUnit">
            <div class="sUnitTitle">块高</div>
            <div class="sUnitVal">{{ tongjiObj.block_tot }}</div>
            <div class="sUnitIcon1">
              <img src="@/assets/images/yk/jicon1.png" alt="" />
            </div>
          </div>
          <div class="smallUnit">
            <div class="sUnitTitle">交易总量</div>
            <div class="sUnitVal">{{ tongjiObj.tx_tot }}</div>
            <div class="sUnitIcon2">
              <img src="@/assets/images/yk/jicon2.png" alt="" />
            </div>
          </div>
          <div class="smallUnit">
            <div class="sUnitTitle">账户数</div>
            <div class="sUnitVal">{{ tongjiObj.account_tot }}</div>
            <div class="sUnitIcon3">
              <img src="@/assets/images/yk/jicon3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="line1b">
          <div class="smallUnit">
            <div class="sUnitTitle">节点数</div>
            <div class="sUnitVal">{{ tongjiObj.node_tot }}</div>
            <div class="sUnitIcon4">
              <img src="@/assets/images/yk/jicon4.png" alt="" />
            </div>
          </div>
          <div class="smallUnit">
            <div class="sUnitTitle">今日交易量</div>
            <div class="sUnitVal">{{ tongjiObj.tx_tot_today }}</div>
            <div class="sUnitIcon5">
              <img src="@/assets/images/yk/jicon5.png" alt="" />
            </div>
          </div>
          <div class="smallUnit">
            <div class="sUnitTitle">部署合约量</div>
            <div class="sUnitVal">{{ tongjiObj.contract_tot }}</div>
            <div class="sUnitIcon6">
              <img src="@/assets/images/yk/jicon6.png" alt="" />
            </div>
          </div>
        </div>
        <div class="line1c">
          <div class="jiedianbox">
            <div class="jdtitle">
              <div class="titleicon">
                <img src="@/assets/images/yk/jdicon1.png" alt="" />
              </div>
              碳低链节点
            </div>
            <div id="main" style="width: 100%; height: 290px"></div>
          </div>
        </div>
        <div class="line1d">
          <div class="mapbox">
            <div class="jdtitle">
              <div class="titleicon">
                <img src="@/assets/images/yk/jdicon2.png" alt="" />
              </div>
              节点地区分布
            </div>

            <!---地图-->
            <div class="map">
              <img
                src="@/assets/images/mapimages/map.png"
                width="716"
                height="595"
                usemap="#Map"
                border="0"
              />
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/anhui.png');
                    
                    top: 314px;
                    left: 523px;
                    width: 75px;
                    height: 90px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 355px;
                    left: 545px;
                    z-index: 10;
                  "
                  href="index.html"
                  >安徽</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/neimeng.png')
                      no-repeat 0 0;
                    top: 9px;
                    left: 296px;
                    width: 318px;
                    height: 272px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 211px;
                    left: 424px;
                    z-index: 10;
                  "
                  href="index.html"
                  >内蒙</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/heilongjiang.png')
                      no-repeat 0 0;
                    top: 1px;
                    left: 550px;
                    width: 165px;
                    height: 151px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 81px;
                    left: 624px;
                    z-index: 10;
                  "
                  href="index.html"
                  >黑龙江</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/xinjiang.png')
                      no-repeat 0 0;
                    top: 73px;
                    left: 0px;
                    width: 292px;
                    height: 223px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 211px;
                    left: 124px;
                    z-index: 10;
                  "
                  href="index.html"
                  >新疆</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/xizang.png')
                      no-repeat 0 0;
                    top: 275px;
                    left: 31px;
                    width: 281px;
                    height: 175px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 361px;
                    left: 145px;
                    z-index: 10;
                  "
                  href="index.html"
                  >西藏</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/qinghai.png')
                      no-repeat 0 0;
                    top: 240px;
                    left: 182px;
                    width: 185px;
                    height: 135px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 300px;
                    left: 254px;
                    z-index: 10;
                  "
                  href="index.html"
                  >青海</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/gansu.png')
                      no-repeat 0 0;
                    top: 187px;
                    left: 236px;
                    width: 207px;
                    height: 177px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 310px;
                    left: 364px;
                    z-index: 10;
                  "
                  href="index.html"
                  >甘肃</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/ningxia.png')
                      no-repeat 0 0;
                    top: 245px;
                    left: 379px;
                    width: 49px;
                    height: 75px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 272px;
                    left: 390px;
                    z-index: 10;
                  "
                  href="index.html"
                  >宁夏</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/shanghai.png')
                      no-repeat 0 0;
                    top: 354px;
                    left: 610px;
                    width: 23px;
                    height: 22px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 352px;
                    left: 615px;
                    z-index: 10;
                  "
                  href="index.html"
                  >上海</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/liaoning.png')
                      no-repeat 0 0;
                    top: 161px;
                    left: 557px;
                    width: 91px;
                    height: 87px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 180px;
                    left: 600px;
                    z-index: 10;
                  "
                  href="index.html"
                  >辽宁</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/guangdong.png')
                      no-repeat 0 0;
                    top: 470px;
                    left: 464px;
                    width: 111px;
                    height: 88px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 490px;
                    left: 500px;
                    z-index: 10;
                  "
                  href="index.html"
                  >广东</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/guangxi.png')
                      no-repeat 0 0;
                    top: 454px;
                    left: 382px;
                    width: 118px;
                    height: 92px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 488px;
                    left: 432px;
                    z-index: 10;
                  "
                  href="index.html"
                  >广西</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/henan.png')
                      no-repeat 0 0;
                    top: 288px;
                    left: 461px;
                    width: 118px;
                    height: 92px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 320px;
                    left: 490px;
                    z-index: 10;
                  "
                  href="index.html"
                  >河南</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/shanxi.png')
                      no-repeat 0 0;
                    top: 242px;
                    left: 396px;
                    width: 79px;
                    height: 134px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 321px;
                    left: 430px;
                    z-index: 10;
                  "
                  href="index.html"
                  >陕西</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/shanxi2.png')
                      no-repeat 0 0;
                    top: 219px;
                    left: 458px;
                    width: 56px;
                    height: 112px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 271px;
                    left: 470px;
                    z-index: 10;
                  "
                  href="index.html"
                  >山西</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/hebei.png')
                      no-repeat 0 0;
                    top: 184px;
                    left: 497px;
                    width: 85px;
                    height: 118px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 247px;
                    left: 508px;
                    z-index: 10;
                  "
                  href="index.html"
                  >河北</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/jilin.png')
                      no-repeat 0 0;
                    top: 113px;
                    left: 575px;
                    width: 125px;
                    height: 88px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 150px;
                    left: 642px;
                    z-index: 10;
                  "
                  href="index.html"
                  >吉林</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/beijing.png')
                      no-repeat 0 0;
                    top: 210px;
                    left: 512px;
                    width: 50px;
                    height: 38px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 215px;
                    left: 515px;
                    z-index: 10;
                  "
                  href="index.html"
                  >北京</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/tianjin.png')
                      no-repeat 0 0;
                    top: 224px;
                    left: 535px;
                    width: 26px;
                    height: 34px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 229px;
                    left: 535px;
                    z-index: 10;
                  "
                  href="index.html"
                  >天津</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/shandong.png')
                      no-repeat 0 0;
                    top: 256px;
                    left: 521px;
                    width: 103px;
                    height: 68px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 281px;
                    left: 540px;
                    z-index: 10;
                  "
                  href="index.html"
                  >山东</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/jiangsu.png')
                      no-repeat 0 0;
                    top: 305px;
                    left: 539px;
                    width: 93px;
                    height: 72px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 321px;
                    left: 570px;
                    z-index: 10;
                  "
                  href="index.html"
                  >江苏</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/hainan.png')
                      no-repeat 0 0;
                    top: 556px;
                    left: 442px;
                    width: 89px;
                    height: 88px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 565px;
                    left: 450px;
                    z-index: 10;
                  "
                  href="index.html"
                  >海南</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/hubei.png')
                      no-repeat 0 0;
                    top: 345px;
                    left: 438px;
                    width: 115px;
                    height: 75px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 365px;
                    left: 480px;
                    z-index: 10;
                  "
                  href="index.html"
                  >湖北</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/yunnan.png')
                      no-repeat 0 0;
                    top: 412px;
                    left: 280px;
                    width: 132px;
                    height: 138px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 485px;
                    left: 320px;
                    z-index: 10;
                  "
                  href="index.html"
                  >云南</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/sichuan.png')
                      no-repeat 0 0;
                    top: 328px;
                    left: 284px;
                    width: 161px;
                    height: 143px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 385px;
                    left: 345px;
                    z-index: 10;
                  "
                  href="index.html"
                  >四川</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/guizhou.png')
                      no-repeat 0 0;
                    top: 409px;
                    left: 367px;
                    width: 93px;
                    height: 81px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 445px;
                    left: 405px;
                    z-index: 10;
                  "
                  href="index.html"
                  >贵州</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/taiwan.png')
                      no-repeat 0 0;
                    top: 456px;
                    left: 613px;
                    width: 32px;
                    height: 65px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 475px;
                    left: 620px;
                    z-index: 10;
                  "
                  href="index.html"
                  >台湾</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/fujian.png')
                      no-repeat 0 0;
                    top: 415px;
                    left: 548px;
                    width: 70px;
                    height: 84px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 445px;
                    left: 565px;
                    z-index: 10;
                  "
                  href="index.html"
                  >福建</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/hunan.png')
                      no-repeat 0 0;
                    top: 394px;
                    left: 445px;
                    width: 83px;
                    height: 96px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 425px;
                    left: 475px;
                    z-index: 10;
                  "
                  href="index.html"
                  >湖南</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/zhejiang.png')
                      no-repeat 0 0;
                    top: 367px;
                    left: 574px;
                    width: 62px;
                    height: 70px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 395px;
                    left: 588px;
                    z-index: 10;
                  "
                  href="index.html"
                  >浙江</a
                >
              </div>

              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/jiangxi.png')
                      no-repeat 0 0;
                    top: 390px;
                    left: 513px;
                    width: 76px;
                    height: 98px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 425px;
                    left: 525px;
                    z-index: 10;
                  "
                  href="index.html"
                  >江西</a
                >
              </div>
              <div class="city">
                <div
                  class="citybg"
                  style="
                    background: url('./mapimages/chongqing.png')
                      no-repeat 0 0;
                    top: 363px;
                    left: 397px;
                    width: 70px;
                    height: 80px;
                  "
                ></div>
                <a
                  style="
                    position: absolute;
                    top: 390px;
                    left: 420px;
                    z-index: 10;
                  "
                  href="index.html"
                  >重庆</a
                >
              </div>
            </div>

            <!---地图 end-->
          </div>
        </div>
        <div class="line1e"></div>
      </div>


          <!----节点航 end-->



          <div class="right_aa">
            <img
              src="../assets/images/vvv/shouye_chain1.png"
              alt=""
              @click="dialogVisible = true"
              style="cursor:pointer;"
            />

            <img
              src="../assets/images/vvv/shouye_chain2.png"
              @click="dialogqiyeVisible = true"
              alt=""
              style="cursor:pointer;"
            />
            <img
              src="../assets/images/vvv/shouye_chain3.png"
              alt=""
              @click="dialognftVisible = true"
              style="cursor:pointer;"
            />
            <el-dialog :visible.sync="dialogVisible" :show-close="false">
              <div class="chain_dialog">
                <div class="cadi_head">
                  <img
                    src="@/assets/images/yk/titlexian.png"
                    alt=""
                    style="width: 6px"
                  />
                  <span>请选择上链通道</span>
                </div>
                <div class="cadi_body">
                  <div
                    class="cadi_body_box"
                    @click="routerClick({ name: 'meetingChain' })"
                  >
                    <h1>自行上链</h1>
                    <img
                      src="../assets/images/vvv/shangchuang.png"
                      alt=""
                      style="width: 50px; margin-top: 30px"
                    />

                    <p>图片格式：PNG、JPG，JPEG</p>
                  </div>
                  <div class="cadi_body_box">
                    <h1>前往碳低低</h1>
                    <img
                      src="../assets/images/vvv/tandidiewm.png"
                      alt=""
                      style="margin-top: 20px; width: 100px; "
                    />
                  </div>
                </div>
              </div>
            </el-dialog>
            <el-dialog :visible.sync="dialogqiyeVisible" :show-close="false">
              <div class="chain_dialog">
                <div class="cadi_head">
                  <img
                    src="@/assets/images/yk/titlexian.png"
                    alt=""
                    style="width: 6px"
                  />
                  <span>请选择上链通道</span>
                </div>
                <div class="cadi_body">
                  <div
                    class="cadi_body_box"
                    @click="routerClick({ name: 'qiyeChain' })"
                  >
                    <h1>自行上链</h1>
                    <img
                      src="../assets/images/vvv/shangchuang.png"
                      alt=""
                      style="width: 50px; margin-top: 30px"
                    />
                    <p>图片格式：PNG、JPG，JPEG</p>
                  </div>
                  <div class="cadi_body_box">
                    <h1>前往碳低低</h1>
                    <img
                      src="../assets/images/vvv/tandidiewm.png"
                      alt=""
                      style="margin-top: 20px; width: 100px; "
                    />
                  </div>
                </div>
              </div>
            </el-dialog>
            <el-dialog :visible.sync="dialognftVisible" :show-close="false">
              <div class="chain_dialog">
                <div class="cadi_head">
                  <img
                    src="@/assets/images/yk/titlexian.png"
                    alt=""
                    style="width: 6px"
                  />
                  <span>请选择上链通道</span>
                </div>
                <div class="cadi_body">
                  <div
                    class="cadi_body_box"
                    @click="routerClick({ name: 'nftChain' })"
                  >
                    <h1>自行上链</h1>
                    <img
                      src="../assets/images/vvv/shangchuang.png"
                      alt=""
                      style="width: 50px; margin-top: 30px"
                    />
                    <p>图片格式：PNG、JPG，JPEG</p>
                  </div>
                  <div class="cadi_body_box">
                    <h1>前往碳低低</h1>
                    <img
                      src="../assets/images/vvv/tandidiewm.png"
                      alt=""
                      style="margin-top: 20px; width: 100px; "
                    />
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>

          <el-dialog
            :visible.sync="aaaVisible"
            :show-close="false"
            class="iop"
            @close="aaaclose"
          >
            <div class="wans_img">
              <img
                src="../assets/images/vvv/wanshanrenz.png"
                alt=""
                width="100%"
              />
              <div>完善账号信息</div>
            </div>
            <div class="abac">
              <div class="bcad">认证成功获得<span>100000</span> 燃料</div>
              <div class="ojkl">
                <button class="renz" @click="torrrr">立即认证</button>
                <button class="shaoh" @click="aaaclose">稍后去认证</button>
              </div>
            </div>
          </el-dialog>
          <div class="right_he">
            <div class="elCarousel">
              <el-carousel :interval="2000" height="300px">
                <el-carousel-item v-for="item in bannerList" :key="item">
                  <img
                    :src="item.img_url"
                    alt=""
                    style="width: 100%"
                    @click="contentToWx(item)"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>

            <div class="news">
              <div class="news_head">
                <div
                  v-for="(item, index) in newstabsList"
                  :key="index"
                  @click="tabsClick(index)"
                  :class="tabindex == index ? 'activehead_tab' : 'head_tab'"
                >
                  {{ item }}
                </div>
              </div>
              <div class="news_body" v-show="tabindex == 0">
                <ul v-if="huiyinewsList.length !== 0">
                  <li
                    v-for="(item, index) in huiyinewsList"
                    :key="index"
                    @click="contentToWx(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
                <div v-else class="flexac">
                  <img
                    src="../assets/images/vvv/nodata_em.png"
                    alt=""
                    style="width: 30%; margin-top: 50px"
                  />
                </div>
              </div>
              <div class="news_body" v-show="tabindex == 1">
                <ul v-if="qiyenewsList.length !== 0">
                  <li
                    v-for="(item, index) in qiyenewsList"
                    :key="index"
                    @click="contentToWx(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
                <div v-else class="flexac">
                  <img
                    src="../assets/images/vvv/nodata_em.png"
                    alt=""
                    style="width: 30%; margin-top: 50px"
                  />
                </div>
              </div>
              <div class="news_body" v-show="tabindex == 2">
                <ul v-if="nftnewsList.length !== 0">
                  <li
                    v-for="(item, index) in nftnewsList"
                    :key="index"
                    @click="contentToWx(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
                <div v-else class="flexac">
                  <img
                    src="../assets/images/vvv/nodata_em.png"
                    alt=""
                    style="width: 30%; margin-top: 50px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="right_cc">
            <img
              src="../assets/images/vvv/shouye_icon9.png"
              alt=""
              style="width: 14px"
            />
            <span>数据统计</span>
          </div>
          <div class="right_dd">
            <div class="dd_item">
              <div class="dd_title">
                <img
                  src="../assets/images/vvv/shouye_icon10.png"
                  alt=""
                  style="width: 12px; margin-left: 20px"
                />
                <span>上链记录</span>
              </div>

              <div class="dd_num">{{ chainInfo.tx_tot }}</div>
            </div>
            <div class="dd_item">
              <div class="dd_title">
                <img
                  src="../assets/images/vvv/shouye_icon11.png"
                  alt=""
                  style="width: 12px; margin-left: 20px"
                />
                <span>区块高度</span>
              </div>

              <div class="dd_num">{{ chainInfo.block_tot }}</div>
            </div>
          </div>
          <div class="right_ee">
            <div class="ee_head">
              <div class="head_title">
                <img
                  src="../assets/images/vvv/shouye_icon12.png"
                  alt=""
                  style="width: 14px"
                />
                <span>最新上链</span>
              </div>
              <div
                style="color: #03ddb8; font-size: 12px;cursor:pointer;"
                @click="routerClick({ name: 'allChain' })"
              >
                更多
              </div>
            </div>
            <div class="ee_body">
              <div class="th">
                <div class="td">上链哈希</div>
                <div class="td">区块高度</div>
                <div class="td">上链时间</div>
              </div>
              <div
                class="tr"
                style="cursor:pointer;"
                v-for="(item, index) in chainList"
                :key="index"
                @click="toDetail(item.txhash)"
              >
                <div class="td">{{ item.hashMore }}</div>
                <div class="td">{{ item.block_number }}</div>
                <div class="td">{{ item.ctime }}</div>
              </div>
            </div>
          </div>
          <div style="height:50px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YkHeader from '@/components/yk_Header'
import { getGet, getPost } from '../api/apiList'
import qs from 'qs'
export default {
  components: { YkHeader },
  data() {
    return {
      newstabsList: ['会议碳中和', '企业碳中和', '数字藏品'],
      tabindex: 0,
      dialogVisible: false,
      dialogqiyeVisible: false,
      dialognftVisible: false,
      token: '',
      bannerList: [],
      chainInfo: {},
      chainList: [],
      qiyenewsList: [],
      huiyinewsList: [],
      nftnewsList: [],
      coin: '0',
      blockchainCount: 0,
      userInfo: {},
      chainDataCount: 0,
      aaaVisible: false,
      userLevel:0,
      tongjiObj: {},
      nodeList: [],
      chainArr: [],
    }
  },
  methods: {
    getTongji() {
      let url1 = "chain/info?wx_appid=30001";
      let data1 = {};

      getGet(url1, data1).then((res) => {
        if (res.code == 1) {
          this.tongjiObj = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    torrrr() {
      this.$router.push('zhanghaoxinxi')
      sessionStorage.setItem('introduce', 'true')
    },
    aaaclose() {
      this.aaaVisible = false
      sessionStorage.setItem('introduce', 'true')
      console.log('关闭了')
    },
    tabsClick(index) {
      this.tabindex = index
    },
    routerClick(name) {
      this.$router.push(name)
    },
    dialogClick() {
      this.dialogVisible = true
    },

    //新闻联播图
    newsBannerList() {
      console.log('news')
      let url = 'news/news_banner?wx_appid=30001'
      let data = {}
      console.log(data, '888888877')
      let data2 = qs.stringify(data)
      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          res.data.lists.forEach((item) => {
            if (item.banner_status == 1) {
              this.bannerList.push(item)
              console.log(this.bannerList)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //新闻
    qiyenews() {
      console.log('news')
      let url = 'news/news_list?wx_appid=30001'
      let data = {
        curpage: 1,
        page: 4,
        type: 1,
      }
      console.log(data, '888888877')
      let data2 = qs.stringify(data)
      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.qiyenewsList = res.data.lists
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    huiyinews() {
      console.log('news')
      let url = 'news/news_list?wx_appid=30001'
      let data = {
        curpage: 1,
        page: 4,
        type: 4,
      }
      console.log(data, '888888877')
      let data2 = qs.stringify(data)
      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.huiyinewsList = res.data.lists
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    nftnews() {
      console.log('news')
      let url = 'news/news_list?wx_appid=30001'
      let data = {
        curpage: 1,
        page: 4,
        type: 6,
      }
      console.log(data, '888888877')
      let data2 = qs.stringify(data)
      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.nftnewsList = res.data.lists
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    contentToWx(item) {
      console.log(item.wx_url)
      window.open(item.link_url)
    },
    //上链列表
    getChainList() {
      console.log('newchainInfos')

      let url1 = 'chain/list?wx_appid=30001'
      let data1 = { is_new: 1 }
      let data2 = qs.stringify(data1)
      getPost(url1, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          res.data.lists.forEach((item) => {
            if (
              item.txhash !== '' ||
              item.txhash !== null ||
              item.txhash !== undefined
            ) {
              var hashMore = item.txhash
              if(hashMore){
                console.log(item.txhash)
                var hash3 = hashMore.substring(0, 3)
                var length = hashMore.length
                var hash4 = hashMore.substring(length - 4, length)
                item.hashMore = hash3 + '...' + hash4
              }
              
            }
          })
          this.chainList = res.data.lists
          console.log(this.chainList)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //区块信息
    getChainInfo() {
      let url1 = 'chain/info?wx_appid=30001'
      let data1 = {}

      getGet(url1, data1).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.chainInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //认证情况
    getAuthInfo() {
      let url = `member_auth/auth_info?wx_appid=30001&token=${this.token}`
      let data = {}
      let data2 = qs.stringify(data)

      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.coin = res.data.member_data_info.coin
          this.blockchainCount = res.data.blockchain_count
          console.log(this.coin, this.blockchainCount)
          if (res.data.member_info.member_is_auth == 0) {
            this.aaaVisible = true
          }
          if (sessionStorage.getItem('introduce') === 'true') {
            this.aaaVisible = false
          }
        } else {
          //this.$message.error(res.msg)
        }
      })
    },
    //用户信息
    getUserInfo() {
      let url = `auto/userinfo?wx_appid=30001&token=${this.token}`
      let data = {}
      let data2 = qs.stringify(data)

      getPost(url, data2).then((res) => {
        if (res.code == 1) {
          console.log(res)
          this.userInfo = res.data.userInfo
          this.chainDataCount = res.data.chain_data_count

          sessionStorage.setItem('userLevel',res.data.userInfo.level)
          this.userLevel = res.data.userInfo.level

        } else {
          //this.$message.error(res.msg)
        }
      })
    },
    toDetail(txhash) {
      this.$router.push({
        name: 'lianDetailmm',
        query: {
          txhash: txhash,
        },
      })
    },
     getNodeList() {
      let url1 = "chain/node_list?wx_appid=30001";
      let data1 = {};

      getGet(url1, data1).then((res) => {
        if (res.code == 1) {
          this.nodeList = res.data;

          /**chart start */
          var myChart = echarts.init(document.getElementById("main"));

          var nodes = [];

          for(var n=0; n<this.nodeList.length; n++){
            nodes.push({
              '城市': this.nodeList[n].state_name,
              '机构名称': this.nodeList[n].jg_name,
              '类别': "一类节点",
            })
          }

          // var links = [
          //   [this.nodeList[0].state_name, this.nodeList[1].state_name, ""],
          //   [this.nodeList[0].state_name, this.nodeList[2].state_name, ""],
          //   [this.nodeList[0].state_name, this.nodeList[3].state_name, ""],
          //   [this.nodeList[0].state_name, this.nodeList[4].state_name, ""],

          //   [this.nodeList[1].state_name, this.nodeList[2].state_name, ""],
          //   [this.nodeList[1].state_name, this.nodeList[3].state_name, ""],
          //   [this.nodeList[1].state_name, this.nodeList[4].state_name, ""],


          //   [this.nodeList[2].state_name, this.nodeList[3].state_name,""],
          //   [this.nodeList[2].state_name, this.nodeList[4].state_name, ""],

          //   [this.nodeList[3].state_name, this.nodeList[4].state_name, ""],
          // ];

          var links = [];

          for(let yi=0; yi<this.nodeList.length-1;yi++){
            for(let er=(yi+1);er<this.nodeList.length;er++){
              links.push([this.nodeList[yi].state_name,this.nodeList[er].state_name,''])
            }
          }

          // var links = [];
          // for(var l=0; l<this.nodeList.length+2;l++){
          //   //console.log('l',this.nodeList[l+1].state_name)
          //   if(l<this.nodeList.length){
          //      if((l+1) >= this.nodeList.length){
          //        continue;
          //      }else{
          //        links.push([this.nodeList[l].state_name,this.nodeList[(l+1)].state_name,''])
          //      }
          //   }else{
          //     links.push([this.nodeList[this.nodeList.length-1].state_name,this.nodeList[l-3].state_name,""])
          //     links.push([this.nodeList[this.nodeList.length-2].state_name,this.nodeList[l-4].state_name,""])
          //   }
          // }

          
          var infos = {};
          for (var node of nodes) {
            infos[node["城市"]] = Object.assign({}, node);
          }
          var legend = new Set();
          for (var node of nodes) {
            legend.add(node["类别"]);
          }
          legend = Array.from(legend);
          legend.sort();

          var echar_data = [];
          for (var node of nodes) {
            echar_data.push({
              name: node["城市"],
              category: legend.findIndex((element) => element == node["类别"]),
              draggable: true,
            });
          }

          var echar_links = [];
          for (var link of links) {
            echar_links.push({
              source: link[0],
              target: link[1],
              value: link[2],
            });
          }

          var option = {
            title: {
              text: "",
            },
            tooltip: {
              trigger: "item",
              formatter: function (params) {
                if (params.dataType == "edge") {
                  // var info = '<p style="font-size:14px">' + params.name + "</p>";
                  // info = info + '<p style="font-size:14px">' + params.value + "</p>";
                  var info = ``;
                  return info;
                }

                if (params.name in infos) {
                 var info = '';
                 for (var p in infos[params.name]) {
                      if(p != '类别'){
                        info = info + '<p style = "font-size:12px" >' + p + '：' + infos[params.name][p] + '</p>'
                      }
                  }
                  return info;
                }
              },
              backgroundColor: "#264942", //提示标签背景颜色
              textStyle: {
                color: "#fff",
              }, //提示标签字体颜色
            },
            animationDurationUpdate: 1500,
            animationEasingUpdate: "quinticInOut",
            label: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 10,
                },
              },
            },
            legend: {
              x: "center",
              show: false,
              data: legend,
            },

           
            series: [
              {
                type: "graph",
                layout: "force",
                symbolSize: 35,
                focusNodeAdjacency: true,
                roam: true,
                categories: [
                  {
                    name: legend[0],
                    itemStyle: {
                      normal: {
                        color: "#28ccac",
                      },
                    },
                  },
                  {
                    name: legend[1],
                    itemStyle: {
                      normal: {
                        color: "#28ccac",
                      },
                    },
                  },
                  {
                    name: legend[2],
                    itemStyle: {
                      normal: {
                        color: "#28ccac",
                      },
                    },
                  },
                  {
                    name: legend[3],
                    itemStyle: {
                      normal: {
                        color: "#28ccac",
                      },
                    },
                  },
                  {
                    name: legend[4],
                    itemStyle: {
                      normal: {
                        color: "#28ccac",
                      },
                    },
                  },
                ],
                label: {
                  normal: {
                    show: true,
                    textStyle: {
                      fontSize: 12,
                      color: "#333",
                    },
                  },
                },
                force: {
                  repulsion: 1000,
                  edgeLength:130
                },
                edgeSymbolSize: [4, 50],
                edgeLabel: {
                  normal: {
                    show: true,
                    textStyle: {
                      fontSize: 10,
                    },
                    formatter: "{c}",
                  },
                },
                data: echar_data,
                links: echar_links,
                lineStyle: {
                  normal: {
                    opacity: 0.9,
                    width: 1,
                    curveness: 0,
                  },
                },
              },
            ],
          };

          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);

          myChart.on("mouseover", function (params) {
            console.log("e", params.data.name);
             var currentCity = params.data.name;

              for(var c=0; c<$('.citybg').length;c++){
                if(currentCity.indexOf($($('.city')[c]).find('a').text()) != -1 ){
                  $($('.city')[c]).find('.citybg').show();
                  $($('.city')[c]).siblings().find('.citybg').hide();
                  $($('.city')[c]).find('a').css('color','#333');
                }
              }
          });

          /**chart end */
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    if (
      this.$store.state.userInfo === '' ||
      this.$store.state.userInfo === null ||
      this.$store.state.userInfo === undefined
    ) {
      this.$router.push('login')
    } else {
      this.token = this.$store.state.userInfo.token
    }

    this.newsBannerList()
    this.qiyenews()
    this.huiyinews()
    this.nftnews()
    this.getChainInfo()
    this.getAuthInfo()
    this.getUserInfo()

    this.getChainList()
  },
  mounted() {
    console.log(sessionStorage.getItem('introduce') == 'true', '9999')

    console.log(this.aaaVisible, '78878')

    this.getTongji();
    this.getNodeList();
  },
}
</script>

<style lang="less" scoped>
@import '../assets/yk_global.css';
.iop {
  .wans_img {
    position: relative;
    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  /deep/ .el-dialog {
    width: 317px;
    background: #232323;
    border-radius: 7px;
  }
  /deep/ .el-dialog__header {
    display: none;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .ojkl {
    margin-top: 40px;
  }
  .abac {
    background: #232323;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
    .bcad {
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;
      span {
        color: #03ddb8;
        text-decoration: underline;
      }
    }
    .renz {
      width: 100px;
      text-align: center;
      line-height: 40px;
      height: 40px;
      background: #03ddb8;
      border-radius: 3px 3px 3px 3px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-right: 20px;
      border: none;
    }
    .shaoh {
      width: 100px;
      height: 40px;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      background-color: transparent;
      border: 1px solid #707070;
      text-align: center;
      line-height: 40px;
      font-size: 14px;

      color: #ffffff;
      font-weight: 500;
    }
  }
}
.flexac {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  background-color: #000;
  height: 64px;
  display: flex;
  align-items: center;
  .header_logo {
    width: 100px;
    margin-left: 63px;
  }
  .logo_title {
    width: 300px;
    margin-left: 32px;
  }
}
.body {
  margin-top: 16px;
  // background-color: #000;
  // position: relative;
  .body_psa {
    // position: absolute;
    display: flex;
    // left: 0;
    // top: 0;
    // width: 100%;
  }
  .body_left {
    margin-left: 30px;
    width: 250px;
    height: 637px;
    border-radius: 10px;
    background: rgba(27, 27, 27, 0.8);
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #ffffff;
    .touxiang {
      width: 68px;
      border-radius: 50%;
      margin-top: 38px;
    }
    .left_nicheng {
      margin-top: 23px;
      font-size: 13px;
    }
    .left_record {
      display: flex;
      width: 100%;
      margin-bottom: 30px;
      .br {
        border-right: 1px solid #333333;
      }
      .record_box {
        width: 50%;
        height: 44px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        .box_h {
          display: flex;
          align-items: center;
          .left_icon {
            width: 14px;
          }
          .chongzhi {
            font-size: 12px;
            transform: scale(0.8);
            color: #10ffd6;
            cursor: pointer;
          }
          span {
            font-size: 12px;
            margin-left: 5px;
          }
        }
        .box_b {
          margin-top: 10px;
          text-align: center;
          font-size: 16px;
        }
      }
    }
    .route_item {
      width: 225px;
      height: 44px;
      background: #232323;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor:pointer;
      .item_l {
        display: flex;
        align-items: center;
        margin-left: 13px;
        span {
          margin-left: 13px;

          font-size: 12px;
        }
      }
    }
  }
  .body_right {
    flex: 1;
    margin-left: 16px;
    width: calc(100%-296px);

    .right_aa {
      margin-top: 16px;
      margin-bottom: 16px;
      /deep/ .el-dialog {
        background: #232323;

        border-radius: 6px;
      }
      img {
        width: 33%;
        
      }
    }
    .right_he {
      display: flex;
      .elCarousel {
        width: 50%;
        overflow: hidden;
        cursor:pointer;
        /deep/ .el-carousel__indicators--horizontal {
          bottom: 10%;
        }
      }
      .news {
        width: 50%;
        border-radius: 6px;
        margin-left: 16px;
        margin-right: 16px;
        height: 300px;
        background: rgba(35, 35, 35, 0.8);
        .news_head {
          height: 50px;
          display: flex;
          background: rgba(0, 0, 0, 0.8);
          cursor:pointer;
          .head_tab {
            height: 50px;

            width: 33%;
            line-height: 50px;
            text-align: center;
          }
          .activehead_tab {
            height: 46px;
            width: 33%;
            line-height: 42px;
            text-align: center;
            border-top: 4px solid #03ddb8;
            background: rgba(35, 35, 35, 0.8);
          }
        }
        .news_body {
          ul {
            margin-top: 30px;
            margin-left: 35px;
            list-style: none;
            li {
              margin-bottom: 30px;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;

              white-space: nowrap;
              width: 82%;
              &::before {
                content: '\2022';
                color: #28f9da;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .right_cc {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      span {
        margin-left: 6px;
        font-size: 14px;

        font-weight: 500;
      }
    }
    .right_dd {
      display: flex;
      .dd_item {
        width: 50%;
        height: 38px;
        background: rgba(35, 35, 35, 0.8);
        border-radius: 6px;
        border: 1px solid #707070;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 16px;
        .dd_title {
          display: flex;
          align-items: center;
          span {
            font-size: 13px;
            margin-left: 8px;
          }
        }
        .dd_num {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }
    .right_ee {
      width: calc(100%-16px);
      height: 245px;
      background: rgba(35, 35, 35, 0.8);
      border-radius: 6px 6px 6px 6px;
      margin-top: 16px;
      margin-right: 16px;
      padding-top: 20px;
      .ee_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px 20px 20px;
        .head_title {
          display: flex;
          align-items: center;
          span {
            font-size: 13px;
            margin-left: 8px;
          }
        }
      }
      .ee_body {
        margin-left: 60px;
        margin-top: 20px;
        .th {
          display: flex;
          align-items: center;
          .td {
            font-size: 10px;
            margin-bottom: 20px;
          }
        }
        .tr {
          display: flex;
          align-items: center;
          color: #999999;

          .td {
            font-size: 10px;
            margin-bottom: 20px;
          }
          &:nth-child(2n + 1) {
            color: #03ddb8;
          }
        }
        .td:nth-child(1) {
          width: 35%;
          min-width: 260px;
        }
        .td:nth-child(2) {
          width: 30%;

          min-width: 240px;
        }
      }
    }
  }
}
.chain_dialog {
  .cadi_head {
    display: flex;
    align-items: center;
    span {
      margin-left: 6px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .cadi_body {
    margin-top: 20px;
    display: flex;
    .cadi_body_box {
      width: 634px;

      height: 195px;
      background: #292929;
      &:first-child {
        margin-right: 34px;
      }
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
      }
      p {
        margin-top: 20px;
        font-size: 14px;
        font-weight: normal;
        color: #666666;
      }
    }
  }
}

.chongzhibtn{
  margin-top:10px;

  >img{
    width:100px;
  }
}


.czbtn{
  width: 80px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background: #03ddb8;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  
  font-size: 13px;
  color: #333333;
  margin-top:10px;
  cursor:pointer;
}


.line1 {
  display: flex;
}

.line1a {
  width: 180px;
  margin-left:6px;
}

.line1b {
  width: 180px;
}

.line1c {
  flex:1;
}

.jiedianbox {
  border: 1px solid #707070;
  border-radius: 5px;
  background: #181818;
  height: 340px;
  position: relative;
  box-sizing: border-box;
}

.line1d {
  width:370px;
  margin-left:20px;

}

.line1e{
  width:20px;
}

.mapbox {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
  background: #181818;
  height: 340px;
  position: relative;
  box-sizing: border-box;
}

.jdtitle {
  padding-top: 20px;
  padding-left: 50px;
  color: #2dffd6;
  font-size: 18px;
  position: relative;
}

.smallUnit {
  width: 160px;
  height: 100px;
  border: 1px solid #707070;
  border-radius: 5px;
  background: #1c1c1c;
  position: relative;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.sUnitTitle {
  color: #2dffd6;
  font-size: 14px;
}

.sUnitVal {
  color: #fff;
  margin-top: 20px;
}

.sUnitIcon1 {
  position: absolute;
  right: 10px;
  top: 16px;
  > img {
    width: 40px;
  }
}

.sUnitIcon2 {
  position: absolute;
  right: 10px;
  top: 16px;
  > img {
    width: 53px;
  }
}

.sUnitIcon3 {
  position: absolute;
  right: 10px;
  top: 22px;
  > img {
    width: 53px;
  }
}

.sUnitIcon4 {
  position: absolute;
  right: 10px;
  top: 13px;
  > img {
    width: 70px;
  }
}

.sUnitIcon5 {
  position: absolute;
  right: 10px;
  top: 20px;
  > img {
    width: 57px;
  }
}

.sUnitIcon6 {
  position: absolute;
  right: 10px;
  top: 20px;
  > img {
    width: 57px;
  }
}

.titleicon {
  position: absolute;
  left: 13px;
  top: 19px;
  > img {
    width: 29px;
  }
}

.map {
  position: relative;
  margin: 0 auto;
  zoom: 0.4;
  margin-left: 60px;
}
.map a {
  // color: #2dffd6;
  color:#fff;
  font-size: 14px;
  text-decoration: none;
}
.map a:hover {
  color: #fff;
}
.citybg {
  position: absolute;
  display: none;
  z-index: 3;
}
.city a.active .citybg {
  display: block;
  z-index: 3;
}

.chatcss {
  border: 1px solid #00f;
}

</style>
